import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { LoginService } from '@services/sesion/login/login.service';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationError,
  NavigationCancel,
  Event,
  ActivatedRoute
} from '@angular/router';
import * as dayjs from 'dayjs';
import { RentacarService } from '@services/autorreserva/rentacar/rentacar.service';
import { ServicioGeneralService } from '@services/general/servicio-general/servicio-general.service';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { Funciones } from '@utiles/funcionesComunes';
import { AuthService } from '@services/sesion/auth/auth.service';
import Swal from 'sweetalert2';
import { first } from 'rxjs/internal/operators/first';
import { TranslateService,TranslateModule } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { map, switchMap } from 'rxjs/operators';
import { EnvironmentEnum } from '@env/environments.enum';
import { SpinnerComponent } from "@destinux/destinux-components";
import { obtenerVariablesCSS } from '@destinux/destinux-components/utiles';
import { ConfiguracionesService } from '@services/configuraciones/configuraciones.service';

@Component({
    selector: 'destinux-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, RouterLink, NgxSpinnerModule, TranslateModule, SpinnerComponent]
})
export class AppComponent {

  //#region declaración propiedades
  backgroundColorRGA = obtenerVariablesCSS("--backgroundLista-base-claro-50");

  userLoggedIn: boolean = false;
  info_user: any;
  info_user_admin: any;
  public loading = false;
  private contadorCaducarSesion: any;
  private suscripcionTiempoSesion: Subscription | undefined;
  private suscripcionLenguaje: Subscription | undefined;
  private _suscripciones: Subscription[] = [];
  //#endregion

  constructor(
    private loginService: LoginService,
    private router: Router,
    private rentaCarService: RentacarService,
    private servicioGeneral: ServicioGeneralService,
    private authService: AuthService,
    private translate: TranslateService,
    private config: PrimeNGConfig,
    private _activatedRoute: ActivatedRoute,
    private _configuracion: ConfiguracionesService
  ) {
    this.suscribirLenguaje();

    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.deleteStates(event);
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    this._configuracion.getExpresionesRegulares().pipe(first()).subscribe(x => {
      this._configuracion.expresionesRegulares = x;
    });

    //#region Control de sesión caducada cuando se reabre el navegador
    let actionTemp = dayjs(localStorage.getItem("ActionTemp"), 'DD-MM-YYYY HH:mm:ss');
    let ahora = dayjs();

    if (ahora.isAfter(actionTemp.add(600, 'seconds'))) {
      this.loginService.getLogOut(true);
    }
    //#endregion

    this.servicioGeneral.setNavegadorIE(Funciones.detectIE());
    this.suscripcionTiempoSesion = this.authService.tiempoSesion$.subscribe((tiempo) => {
      if (!tiempo) {
        this.authService.setTiempoSesion();
      } else {
        if (this._suscripciones.length == 0) {
          this.suscribirUltimaAccion();
          this.suscribirEstaLogueado();
          this.comprobarSesionCaducada();
          this.suscribirLoading();
        }
      }
    });
    this._activatedRoute.url.subscribe((value)=>{
      console.log('activatedUrl:',value);
    });
  }

  cambiarIdiomaFlatpickr(idioma: string) { // NO SE USA
    // const lenguajeParseado = idioma.split('-')[0] as keyof typeof FlatpickrLanguages;
    // flatpickr.localize(FlatpickrLanguages[lenguajeParseado]);
  }

  ngOnDestroy(): void {
    this.suscripcionLenguaje?.unsubscribe();
    this.suscripcionTiempoSesion?.unsubscribe();
    this._suscripciones.forEach((suscripcion) => {
      suscripcion.unsubscribe();
    });
  }

  cambiarIdioma(idioma: string):void {
    this.translate.use(idioma);
  }

  onActivate(): void {
    let pos = window.pageYOffset; // TODO: change deprecated
    if (pos > 0) {
      window.scrollTo(0, 0);
    }
  }

  deleteStates(event: any): void {
    if (event.url !== '/autorreserva/rentacar' && event.url !== '/autorreserva/rentacar/prereserva') {
      this.rentaCarService.deleteStateRentACar();
    }
  }

  suscribirLenguaje() {
    this.suscripcionLenguaje = this.servicioGeneral.lenguaje$.subscribe((lenguaje) => {
      lenguaje = lenguaje || this.translate.currentLang;
      console.log('app subscript servicioGrl lenguaje$',lenguaje)
      this.translate.use(lenguaje).pipe(
        switchMap(() => this.translate.get("primeng").pipe(
          switchMap(dataPrimeng => this.translate.get("shared.dias").pipe(
            switchMap(dataDias => this.translate.get("shared.mes").pipe(
              map(dataMes => [dataPrimeng, dataDias, dataMes]))
            )
          )))
        )).subscribe(([dataPrimeng, dataDias, dataMes]) => {
          const ordenDias = ["domingo", "lunes", "martes", "miercoles", "jueves", "viernes", "sabado"];
          const ordenMeses = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];

          dataPrimeng.dayNames = ordenDias.map(x => dataDias.entero[x]);
          dataPrimeng.dayNamesShort = ordenDias.map(x => dataDias.corto[x]);
          dataPrimeng.dayNamesMin = ordenDias.map(x => dataDias.min[x]);

          dataPrimeng.monthNames = ordenMeses.map(x => dataMes.entero[x]);
          dataPrimeng.monthNamesShort = ordenMeses.map(x => dataMes.corto[x]);

          this.config.setTranslation(dataPrimeng);
        });

      // this.cambiarIdiomaFlatpickr(lenguaje); // NO SE USA
    });
  }

  suscribirUltimaAccion() {
    const LSUltimaAccion = localStorage.getItem('ActionTemp');
    if (LSUltimaAccion) this.authService.setUltimaAccion(dayjs(LSUltimaAccion, 'DD-MM-YYYY HH:mm:ss'));
    this._suscripciones.push(
      this.authService.ultimaAccion$.subscribe(() => {
          if (environment.entorno != EnvironmentEnum.DEMO) {
          this.mostrarModalSigueActivo();
        }
      })
    );
  }

  mostrarModalSigueActivo() {
    if (this.contadorCaducarSesion) clearTimeout(this.contadorCaducarSesion);
    if (!this.authService.sesionCaducada) {
      this.contadorCaducarSesion = setTimeout(() => {
        Swal.fire({  // TODO: modificar antes de publicar en producción
          title: this.translate.instant('modal-sigue-activo.titulo'),
          html: this.translate.instant('modal-sigue-activo.texto'),
          timer: this.authService.tiempoSesion - this.authService.getTiempoSesionGastado(),
          timerProgressBar: true,
          showCancelButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonText: this.translate.instant('modal-sigue-activo.seguir-activo'),
          cancelButtonText: this.translate.instant('modal-sigue-activo.cerrar-sesion'),
          cancelButtonColor: '#F44336'
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            this.loginService.getLogOut(true);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            this.loginService.GetLogOutPorUsuario();
          } else if (result.isConfirmed) {
            this.authService
              .mantenerSesionActiva()
              .pipe(first())
              .subscribe({
                next: () => { },
                error: () => {
                  this.loginService.getLogOut(true);
                }
          });
          }
        });
      }, this.authService.tiempoSesion - 120000 - this.authService.getTiempoSesionGastado());
    }
  }

  suscribirEstaLogueado() {
    this._suscripciones.push(
      this.loginService.getLoggedIn().subscribe((logueado: any) => {
        this.userLoggedIn = logueado;
      })
    );
    this.info_user = JSON.parse(localStorage.getItem('info_user') || '');
    this.info_user_admin = JSON.parse(localStorage.getItem('info_user_admin') || '');
    this.loginService.setLoggedIn(this.info_user || this.info_user_admin ? true : false);
  }

  comprobarSesionCaducada() {
    this._suscripciones.push(
      this.router.events.subscribe(() => {
        if (this.userLoggedIn) {
          if (this.authService.sesionCaducada) {
            this.loginService.getLogOut(true);
          }
        }
      })
    );
  }

  suscribirLoading() {
    this._suscripciones.push(
      this.servicioGeneral.loading$.subscribe((valor) => (this.loading = valor ? true : false))
    );
  }

}
